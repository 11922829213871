import React from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const BicakIziCizimiveAmbalaj = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)


  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Bıçak Çizimi ve Ambalaj Hazırlığı ' />
      <Divider space='5' />
      <h3 style={{ color: "black", textAlign: "center" }}>Bıçak Çizimi ve Ambalaj Hazırlığı</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;Bir ambalaj tasarımı işlemine başlamadan önce, tasarlanacak ambalajın koruyacağı ürünün niteliklerine göre belirlenmesidir.Ambalajı yapılacak ürünün ağırlığı sektörel anlamda kullanılacak alanların belirlenmesi ,Gıda,Otomotiv,kozmetik,Oyuncak,deri ürünleri,elektirikli el aletleri ev teknolojileri gibi Vb. ürünlerin uygun koruma şartlarını bilmek buna göre uygun olan tasarımı ve kağıt cinsini belirlemek gerekir.Ambalaj tasarımını yaparken kağıt atıkların çevresel etkilerini göz önünde bulunduralım</div>
      </div>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageBicakIziCizimiveAmbalajContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default BicakIziCizimiveAmbalaj
